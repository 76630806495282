jQuery(function ($) {
    // let isMobile = navigator.userAgentData.mobile;
    let windowWidth = window.innerWidth;
    let navH = windowWidth < 1024 ? 50 : 100;

    $(window).on("load resize", function () {
        // if(!isMobile) {
        let windowW = window.innerWidth;
        let windowH = window.innerHeight - navH;
        let newWidth = 1.777777777777778 * windowH;

        $(".people-grid--blocks").width(newWidth);

        let newTextW = windowW / newWidth;
        if (newTextW < 1) {
            newTextW = newTextW < 0.6 ? 0.6 : newTextW;
            // $(".people-grid--slide-text").css(
            //     "transform",
            //     "scale(" + newTextW + ")"
            // );
        }
        // }
    });

    gsap.registerPlugin(ScrollTrigger);

    // Pin the section when it enters the viewport
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".people-grid",
            start: "top 0%+=" + navH + "px",
            end: "+=" + window.innerHeight * 5,
            pin: true,
            scrub: 0.1,
            markers: false,
        },
    });

    tl.to(".people-grid--slide-1", { opacity: 1, duration: 1 });

    for (var i = 1; i <= 10; i++) {
        if ($(".people-grid--slide-" + (i + 1)).length > 0) {
            tl.to(".people-grid--slide-" + i + " .people-grid--slide-text", {
                opacity: 0,
                duration: 1,
            });
            tl.to(".people-grid--slide-" + (i + 1), {
                opacity: 1,
                duration: 1,
            });
        }
    }
});
